/* @import url("https://hangeul.pstatic.net/hangeul_static/css/nanum-gothic.css");
@import url("https://hangeul.pstatic.net/hangeul_static/css/nanum-myeongjo.css");
@import url("https://hangeul.pstatic.net/hangeul_static/css/nanum-pen.css"); */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
